.lounge-zone {
  padding-top: 64px;
  padding-bottom: 64px;
  background: var(--black-90);
}
.lounge-zone__title {
  font-family: Dela Gothic One;
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 12px;
}
.lounge-zone__title-lounge {
  color: var(--gold);
}
.lounge-zone__icon {
  fill: var(--gold);
}
.lounge-zone__desc-first {
  font-size: 18px;
  font-weight: 700;
}
.lounge-zone__desc-first-part {
  color: var(--gold);
}
.lounge-zone__desc-wrapper {
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  margin-bottom: 40px;
}
.lounge-zone__desc-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
}
.lounge-zone__desc {
  font-size: 16px;
  font-weight: 500;
}
.lounge-zone__desc-gold {
  color: var(--gold);
}
.lounge-zone__gallery-big-photo {
  border-radius: 8px;
  width: 100%;
  height: 270px;
  margin-bottom: 20px;
  object-fit: cover;
}
.lounge-zone__gallery-small-photo-desktop {
  display: none;
}
.lounge-zone__gallery-small-photo {
  width: 161px;
  height: 138;
  border-radius: 8px;
  object-fit: cover;
}

.lounge-zone__gallery-container-small-photo {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
@media screen and (min-width: 768px) {
  .lounge-zone {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .lounge-zone__title {
    font-size: 40px;
    margin-bottom: 12px;
  }
  .lounge-zone__desc-first {
    font-size: 16px;
  }
  .lounge-zone__desc-wrapper {
    justify-content: flex-start;
    margin-bottom: 60px;
  }
  .lounge-zone__desc-photo-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 20px;
  }
  .lounge-zone__desc-photo-wrapper > * {
    flex: 1 1 calc(50% - 20px);
    max-width: calc(50% - 20px);
  }
  .lounge-zone__gallery-big-photo {
    width: 100%;
    min-height: 390px;
  }
  .lounge-zone__desc-container {
    width: 100%;
  }
  .lounge-zone__gallery-container-small-photo {
    display: none;
  }
  .lounge-zone__gallery-small-photo-desktop {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
  }
  .lounge-zone__gallery-small-photo {
    height: 246px;
    width: 228px;
  }
  .lounge-zone__gallery-big-photo {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 1200px) {
  .lounge-zone {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .lounge-zone__gallery-small-photo {
    height: 350px;
    width: 387px;
  }
  .lounge-zone__title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 40px;
  }
  .lounge-zone__desc-wrapper {
    margin-bottom: 0;
  }
  .lounge-zone__title {
    margin-bottom: 0;
  }
}
