.slider-car {
  display: none;
}

@media screen and (min-width: 1200px) {
  .slider-car {
    display: block;
    padding: 100px 0;
    margin-left: 22px;
    margin-right: 22px;
    overflow: hidden;
  }

  .slider-car__container {
    transform: translateZ(0);
    transition: transform 1s ease;
  }

  .slider-car__list {
    max-width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .slider-car__item {
    flex: 0 0 5%;
    overflow: hidden;
    margin: 0 10px;
    transition: flex 1s ease;
    border-radius: 4px;
  }

  .slider-car__item.active {
    flex: 0 0 76%;
    margin: 0;
    min-width: 690px;
    transition: flex 1s ease;
  }

  .slider-car__photo {
    width: 100%;
    height: auto;
    border-radius: 4px;
    object-fit: cover;
    opacity: 0.15;
    transition: opacity 1s ease, width 1s ease, height 1s ease;
  }

  .slider-car__photo.active {
    opacity: 1;
    min-width: 82px;
    height: 700px;
    transition: opacity 1s ease, min-width 1s ease, height 1s ease;
  }
}
