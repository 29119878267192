:root {
  --gold: #e67e22;
  --gold-80: #e66a22;
  --white: #ffffff;
  --black-90: #0a0a0a;
  --black-100: #000000;
  --black-gold: #151206;
  --red: #ff290a;
  --transition-dur-and-func: 250ms cubic-bezier(0.4, 0, 0.2, 1);
}
