.gallery .gallery__container {
  padding-top: 64px;
  padding-bottom: 94px;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}
.gallery__title {
  padding-left: 16px;
  color: var(--gold);
  font-family: Dela Gothic One;
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 12px;
}
.gallery__desc {
  padding-left: 16px;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
}
.gallery__slider-small {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 120px;
}
.gallery__photo {
  border-radius: 8px;
  cursor: pointer;
  max-width: 100%;
  transition: transform 1s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  object-fit: cover;
}
.gallery__item .gallery__photo {
  width: 183px;
  height: 116px;
  flex-shrink: 0;
  opacity: 0.3;
  transition: opacity 1s;
  &:hover,
  &:focus {
    opacity: 1;
  }
}
.gallery__item.slick-slide.slick-current.slick-active .gallery__photo {
  opacity: 1;
}
.gallery__slider-big {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  margin-bottom: 20px;
}
.gallery__slider-big .slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.gallery__slider-small .slick-slide {
  margin: 0 10px;
  transition: transform 1s;
}

.gallery__slider-big .slick-slide img {
  height: 370px;
  transition: all 1s;
}

.gallery__slider-big .slick-slide.slick-current img {
  width: 343px;
  height: 232px;
}
.gallery__slider-big .slick-track {
  height: 232px;
}

.gallery__slider-big .slick-slide img {
  margin: 0 10px;
  width: auto;
  height: 200px;
  opacity: 0.3;
  transition: opacity 1s;
  &:hover,
  &:focus {
    opacity: 1;
  }
}
.gallery__slider-big .slick-slide.slick-current img {
  opacity: 1;
}
.slick-track {
  height: auto;
  display: flex;
  align-items: center;
}

.slick-next {
  position: absolute;
  margin-top: 200px;
  font-size: 24px;
  line-height: 1;
  z-index: 1;
  cursor: pointer;
  border: none;
  margin-left: 80px;
}
.slick-prev {
  position: absolute;
  margin-top: 200px;
  font-size: 24px;
  line-height: 1;
  z-index: 1;
  cursor: pointer;
  border: none;
  margin-right: 70px;
}

.slick-prev svg {
  width: 32px;
  height: 32px;
  fill: var(--gold-80);
  background-color: #151206;
  border-radius: 50%;
  z-index: 2;
  transition: fill var(--transition-dur-and-func);
  &:hover,
  &:focus {
    fill: var(--gold);
    transform: scale(1.1);
  }
}
.slick-next svg {
  width: 32px;
  height: 32px;
  fill: var(--gold-80);
  background-color: #151206;
  border-radius: 50%;
  transition: fill var(--transition-dur-and-func);
  &:hover,
  &:focus {
    fill: var(--gold);
    transform: scale(1.1);
  }
}

@media screen and (min-width: 768px) {
  .gallery__slider-big .slick-slide img {
    height: 370px;
    transition: all 1s;
  }

  .gallery__slider-big .slick-slide.slick-current img {
    width: 700px;
    height: 446px;
    transition: all 1s;
  }
  .gallery__slider-big .slick-track {
    height: 446px;
  }
  .gallery {
    padding-top: 80px;
    padding-bottom: 124px;
  }
  .gallery__title {
    padding-left: 22px;
    font-size: 40px;
  }
  .gallery__desc {
    padding-left: 22px;
    font-size: 24px;
  }
}
@media screen and (min-width: 1200px) {
  .gallery__slider-big .slick-slide img {
    height: 360px;
    transition: height 0.5s linear;
  }

  .gallery__slider-big .slick-slide.slick-current img {
    height: 462px;
    width: 794px;
    transition: height 0.5s linear;
  }
  .gallery__slider-big .slick-track {
    height: 462px;
  }
  .slick-prev,
  .slick-next {
    margin-top: 260px;
  }
  .gallery {
    padding-top: 100px;
    padding-bottom: 144px;
  }
}

@media screen and (min-width: 1300px) {
  .gallery__title {
    padding-left: 10%;
  }
  .gallery__desc {
    margin-bottom: 60px;
    padding-left: 10%;
  }
}
@media screen and (min-width: 1300px) {
  .gallery__title {
    padding-left: 14%;
  }
  .gallery__desc {
    padding-left: 14%;
  }
}
@media screen and (min-width: 1920px) {
  .gallery__title {
    padding-left: 18%;
  }
  .gallery__desc {
    padding-left: 18%;
  }
}
