.line {
  // border-top: 2px solid var(--white);
  padding-top: 20px;
  padding-bottom: 18px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  position: relative;
  height: 60px;
  background-color: var(--gold);
  overflow-x: hidden;
}

.line__container {
  display: flex;
  gap: 50px;
  width: 800px;
  position: absolute;
  white-space: nowrap;
  animation: marquee 20s linear infinite;
  animation-delay: 0.5s;
  animation-fill-mode: both;
  animation-timing-function: linear;
}
* {
  box-sizing: border-box;
}
.line__menu {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  position: relative;
}

.line__item {
  color: var(--black-90);
  font-size: 16px;
  font-weight: 700;
  position: relative;
}

.line__item::after {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--black-100);
  position: absolute;
  top: 50%;
  right: -35px;
  transform: translate(-50%, -50%);
}

@keyframes marquee {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@media screen and (min-width: 768px) {
  .line__container {
    max-width: 1200px;
  }
}

@media screen and (min-width: 1200px) {
  .line__container {
    max-width: 1600px;
  }
}
