.partners {
  padding-top: 64px;
  padding-bottom: 64px;
}
.partners__title {
  font-family: Dela Gothic One;
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 12px;
}
.partners__title-ours {
  color: var(--gold);
}
.partners__desc {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
}
.partners__desc-ours {
  color: var(--gold);
}
.partners__list {
  border-radius: 4px;
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 0.8) 100%
    ),
    url('../images/partners/bakground.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  padding: 32px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.partners__item {
  display: flex;
  width: 200px;
  height: 64px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}
.partners__img {
  border-radius: 4px;
  object-fit: cover;
}

@media screen and (min-width: 768px) {
  .partners {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .partners__title {
    font-size: 40px;
  }
  .partners__desc {
    font-size: 24px;
    margin-bottom: 40px;
  }
  .partners__list {
    padding: 32px 22px;
    height: 400px;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
  }
}
@media screen and (min-width: 1200px) {
  .partners {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .partners__list {
    gap: 40px;
  }
}
