.hero-container {
  overflow-x: hidden;
  padding-top: 40px;
  padding-bottom: 74px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.hero-section {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.hero__photo-container {
  width: 100%;
  min-height: 314px;
  overflow: hidden;
  position: relative;
}

.hero__photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  animation: revealPhoto 1.5s linear forwards;
  clip-path: polygon(50% 0%, 100% 0, 66.5% 100%, 0 100%);
}

@keyframes revealPhoto {
  0% {
    clip-path: polygon(50% 0%, 100% 0, 66.5% 100%, 0 100%);
  }
  100% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}

@media screen and (min-width: 768px) {
  .hero__photo-container {
    display: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .hero-container {
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.8) 0%,
        rgba(0, 0, 0, 0.8) 100%
      ),
      url('../images/hero/hero-desktop.jpg');
    background-repeat: no-repeat;
    background-size: cover;

    &[data-page='keramika-dlya-avto'] {
      background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.8) 0%,
        rgba(0, 0, 0, 0.8) 100%
      ),
      url('../images/hero/keramika-dlya-avto.jpg');
    }

    &[data-page='pokleyka-plivky-avto'] {
      background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.8) 0%,
        rgba(0, 0, 0, 0.8) 100%
      ),
      url('../images/hero/pokleyka-plivky-avto.jpg');
    }

    &[data-page='polirovka-avto'] {
      background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.8) 0%,
        rgba(0, 0, 0, 0.8) 100%
      ),
      url('../images/hero/polirovka-avto.jpg');
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1199px) and (min-device-pixel-ratio: 2),
  screen and (min-width: 768px) and (max-width: 1199px) and (min-resolution: 192dpi),
  screen and (min-width: 768px) and (max-width: 1199px) and (min-resolution: 2dppx) {
  .hero-container {
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.8) 0%,
        rgba(0, 0, 0, 0.8) 100%
      ),
      url('../images/hero/hero-desktop@2x.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &[data-page='keramika-dlya-avto'] {
      background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.8) 0%,
        rgba(0, 0, 0, 0.8) 100%
      ),
      url('../images/hero/keramika-dlya-avto.jpg');
    }

    &[data-page='pokleyka-plivky-avto'] {
      background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.8) 0%,
        rgba(0, 0, 0, 0.8) 100%
      ),
      url('../images/hero/pokleyka-plivky-avto.jpg');
    }

    &[data-page='polirovka-avto'] {
      background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.8) 0%,
        rgba(0, 0, 0, 0.8) 100%
      ),
      url('../images/hero/polirovka-avto.jpg');
    }
  }
}

@media screen and (min-width: 1200px) {
  .hero-section {
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.8) 0%,
        rgba(0, 0, 0, 0.8) 100%
      ),
      url('../images/hero/hero-desktop.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &[data-page='keramika-dlya-avto'] {
      background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.8) 0%,
        rgba(0, 0, 0, 0.8) 100%
      ),
      url('../images/hero/keramika-dlya-avto.jpg');
    }

    &[data-page='pokleyka-plivky-avto'] {
      background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.8) 0%,
        rgba(0, 0, 0, 0.8) 100%
      ),
      url('../images/hero/pokleyka-plivky-avto.jpg');
    }

    &[data-page='polirovka-avto'] {
      background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.8) 0%,
        rgba(0, 0, 0, 0.8) 100%
      ),
      url('../images/hero/polirovka-avto.jpg');
    }
  }
}

@media screen and (min-width: 1200px) and (min-device-pixel-ratio: 2),
  screen and (min-width: 1200px) and (min-resolution: 192dpi),
  screen and (min-width: 1200px) and (min-resolution: 2dppx) {
  .hero-section {
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.8) 0%,
        rgba(0, 0, 0, 0.8) 100%
      ),
      url('../images/hero/hero-desktop@2x.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &[data-page='keramika-dlya-avto'] {
      background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.8) 0%,
        rgba(0, 0, 0, 0.8) 100%
      ),
      url('../images/hero/keramika-dlya-avto.jpg');
    }

    &[data-page='pokleyka-plivky-avto'] {
      background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.8) 0%,
        rgba(0, 0, 0, 0.8) 100%
      ),
      url('../images/hero/pokleyka-plivky-avto.jpg');
    }

    &[data-page='polirovka-avto'] {
      background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.8) 0%,
        rgba(0, 0, 0, 0.8) 100%
      ),
      url('../images/hero/polirovka-avto.jpg');
    }
  }
}

.hero__title {
  padding: 0;
  text-align: center;
  margin-bottom: 20px;
  color: var(--gold);
  font-size: 28px;
  font-family: Dela Gothic One;
  font-weight: 400;
  font-display: swap;
  text-transform: uppercase;
}
.hero__title-city {
  color: var(--white);
}
.hero__desc {
  text-align: center;
  color: var(--white);
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
  width: 264px;
}

.hero__btn {
  color: var(--black-100);
  font-size: 16px;
  font-weight: 500;
  margin-top: 16px;
  display: flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  background: var(--gold);
  transition: background var(--transition-dur-and-func);
}

.hero__btn.active {
  background: var(--black-100);
  color: var(--gold);
}

.hero__btn .btn-icon {
  margin-left: 8px;
  display: block;
  fill: var(--black-100);
  width: 32px;
  height: 32px;
  transition: fill var(--transition-dur-and-func);
}

.hero__btn.active .btn-icon {
  fill: var(--gold);
}

@media screen and (min-width: 768px) {
  .hero-container {
    padding-top: 71px;
    padding-bottom: 71px;
  }
  .hero__title {
    margin-bottom: 28px;
    font-size: 64px;
    font-family: Dela Gothic One;
    font-weight: 400;
    line-height: 108.3%;
  }
  .hero__title-city {
    color: var(--white);
    display: block;
    text-align: center;
  }

  .hero__desc {
    margin-bottom: 12px;
    font-size: 24px;
    width: 100%;
  }
}

@media screen and (min-width: 1200px) {
  .hero__btn {
    display: none;
  }
  .hero-container {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .hero__title {
    font-size: 64px;
    font-family: Dela Gothic One;
    font-weight: 400;
    line-height: 1;
  }
  .hero__desc {
    margin-bottom: 0;
  }
  // .hero-section {
  //   height: 74%;
  // }
  .hero__title-city {
    margin-bottom: 40px;
  }
}
