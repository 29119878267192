.advantages {
  padding-top: 64px;
  padding-bottom: 64px;
  background: var(--black-90);
}

.advantages__menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
}

.advantages__item {
  opacity: 0;
  transition: opacity 0.5s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 12px;
  padding: 16px;
  background-color: var(--black-100);
  border-radius: 8px;
  transition: background-color var(--transition-dur-and-func);

  &:hover,
  &:focus {
    background-color: var(--black-gold);
  }

  &-header {
    display: flex;
    gap: 20px;
    align-items: center;
  }
}
.advantages__item.show {
  opacity: 1; /* Когда у карточки есть класс show, она становится видимой */
}

.advantages__title {
  font-size: 24px;
  font-weight: 700;
}
.advantages__desc {
  font-size: 16px;
  font-weight: 400;
}
.advantages__icon {
  fill: var(--gold);
}

@media screen and (min-width: 768px) {
  .advantages {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .advantages__menu {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .advantages__item {
    flex-basis: calc(50% - 10px);
  }
}
@media screen and (min-width: 1200px) {
  .advantages {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .advantages__menu {
    flex-wrap: nowrap;
  }
  .advantages__item {
    flex-basis: calc(25% - 10px);
    width: 285px;
    min-height: 230px;
  }
}
