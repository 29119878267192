@media screen and (min-width: 1200px) {
  #toTop {
    background-color: rgba(255, 204, 51, 0.5);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    cursor: pointer;
    z-index: 9999;
    bottom: 30px;
    right: 20px;
    position: fixed;
    width: 50px;
    height: 50px;
    background-image: url('../images/button-up.png');
    background-repeat: no-repeat;
    background-size: 32px 32px;
    background-position: center;
    animation: pulse 2000ms infinite;
    transition: scale var(--transition-dur-and-func),
      background-color var(--transition-dur-and-func);
    &:hover {
      scale: 1.1;
      background-color: var(--gold-80);
    }
  }
}
