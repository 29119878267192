.about {
  padding-top: 64px;
  padding-bottom: 64px;
}
.about__title {
  margin-bottom: 12px;
  font-size: 24px;
  font-family: Dela Gothic One;
  font-weight: 400;
}
.about__title-two {
  color: var(--gold);
}
.about__desc-title {
  color: var(--gold);
  font-size: 24px;
  font-weight: 500;
}
.about__desc-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  margin-bottom: 20px;
}
.about__desc {
  font-size: 16px;
  font-weight: 400;
}
.about__desc-gold {
  color: var(--gold);
}
.about__desc-mission {
  color: var(--gold-80);
}
.about__desc-number {
  font-size: 80px;
  font-weight: 900;
  color: var(--gold);
  -webkit-text-fill-color: transparent; /* Заполняем текст прозрачным цветом */
  -webkit-text-stroke: 1px var(--gold); /* Ширина обводки и её цвет */
  text-stroke: 1px var(--gold); /* Для поддержки в Firefox и других браузерах */
}

.about__desc-our-mission {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 40px;
}

.about__photo {
  width: 100%;
  border-radius: 4px;
  object-fit: cover;
}
.about__desc-card {
  margin-bottom: 40px;
}
.about__desc-card-two {
  margin-bottom: 40px;
  border-radius: 4px;
  background-color: var(--black-90);
}
@media screen and (min-width: 768px) {
  .about {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .about__title {
    font-size: 40px;
  }
  .about__desc-our-mission {
    font-size: 24px;
  }
  .about__photo {
    min-width: 352px;
    min-height: 380px;
  }
  .about__desc-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .about__desc-card > * {
    flex: 1 1 calc(50% - 10px);
    max-width: calc(50% - 10px);
  }
  .about__desc-card-two {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
  }
  .about__desc-card-three {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .about__desc-card-three > * {
    flex: 1 1 calc(50% - 10px);
    max-width: calc(50% - 10px);
  }
  .about__desc-card-two > * {
    flex: 1 1 calc(50% - 10px);
    max-width: calc(50% - 10px);
  }
  .about__desc {
    margin-top: 0;
    margin-bottom: 0;
  }
  .about__desc-wrapper {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 0;
  }
  .about__desc-our-mission {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1200px) {
  .about__photo {
    // min-width: 580px;
    min-height: 500px;
  }
  .about {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .about__desc-card > * {
    flex: 1 1 calc(50% - 20px);
    max-width: calc(50% - 20px);
  }
  .about__desc-card-three > * {
    flex: 1 1 calc(50% - 20px);
    max-width: calc(50% - 20px);
  }
  .about__desc-card-two > * {
    flex: 1 1 calc(50% - 20px);
    max-width: calc(50% - 20px);
  }
}
