* {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body,
html {
  height: 100%;
}

body {
  background-color: var(--black-100);
  color: var(--white);
  font-family: 'Inter', sans-serif;

  &.no-scroll {
    overflow: hidden;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin: 0;
}

img {
  display: block;
  max-width: 100%;
  /* height: auto; */
}

button {
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.list {
  list-style: none;
  // padding-left: 0px;
  // margin: 0px;
}

.link {
  text-decoration: none;
  color: inherit;
}

// ul,
// ol {
//   //   padding-left: 0;
//   //   margin-top: 0;
//   //   margin-bottom: 0;
//   // list-style: none;
// }

a {
  text-decoration: none;
  color: inherit;
}
