.STO-services {
  padding-top: 64px;
  padding-bottom: 64px;
}
.STO-services__title {
  font-family: Dela Gothic One;
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 12px;
}
.STO-services__title-services {
  color: var(--gold);
}
.STO-services__desc {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 40px;
}
.STO-services__desc-fist {
  color: var(--gold);
}

.STO-services__photo {
  width: 100%;
  min-height: 250px;
  border-radius: 8px;
  margin-bottom: 20px;
}
.STO-services__desc-services-wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 40px;
}
.STO-services__desc-services {
  font-size: 16px;
  font-weight: 400;
}
.STO-services__desc-services-list {
  margin-left: 28px;
}
.STO-services__btn {
  margin-top: 12px;
  width: 100%;
  display: flex;
  padding: 20px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 40px;
  border: 2px solid var(--white);

  color: var(--white);
  font-size: 16px;
  font-weight: 700;
  transition: color var(--transition-dur-and-func),
    border var(--transition-dur-and-func);

  &:hover,
  &:focus {
    border: 2px solid var(--gold-80);
    color: var(--gold);
  }
}

.STO-services__btn.active {
  border: 2px solid var(--gold-80);
  color: var(--gold);
}

.STO-services__btn-icon {
  fill: var(--white);
  transition: fill var(--transition-dur-and-func);

  .STO-services__btn:hover &,
  .STO-services__btn:hover & {
    fill: var(--gold);
  }
}
.STO-services__btn.STO-services__btn.active .STO-services__btn-icon {
  fill: var(--gold);
}

@media screen and (min-width: 768px) {
  .STO-services {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .STO-services__title {
    font-size: 40px;
  }
  .STO-services__desc {
    font-size: 24px;
  }
}
@media screen and (min-width: 1200px) {
  .STO-services {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .STO-services__wrapper {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
  }
  .STO-services__photo {
    min-width: 590px;
    height: 444px;
    margin-bottom: 0;
  }
}
