.services-detailing {
  padding-top: 64px;
  padding-bottom: 64px;
}
.services-detailing__hi {
  color: var(--gold);
  font-family: Dela Gothic One;
  font-size: 24px;
  font-weight: 400;
  padding: 8px 0px;
  margin-bottom: 20px;
}
.services-detailing__desc-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 60px;
}
.services-detailing__desc {
  font-size: 16px;
  font-weight: 400;
}
.services-detailing__img {
  border-radius: 6px;
  width: 100%;
  object-fit: cover;
}
@media screen and (min-width: 768px) {
  .services-detailing {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .services-detailing__hi {
    font-size: 40px;
    margin-bottom: 64px;
  }
  .services-detailing__desc-wrapper {
    margin-bottom: 64px;
  }
}
@media screen and (min-width: 1200px) {
  .services-detailing {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .services-detailing__hi {
    margin-bottom: 40px;
  }
  .services-detailing__img {
    width: 550px;
    height: 400px;
  }
  .services-detailing__title {
    margin-bottom: 40px;
  }
  .services-detailing__container {
    display: flex;
    gap: 40px;
    align-items: center;
    justify-content: space-between;
  }
  .services-detailing__desc-wrapper {
    width: 589px;
    margin-bottom: 0;
  }
}
