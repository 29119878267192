.footer {
  padding-top: 40px;
  padding-bottom: 40px;
}
// .footer__map {
//   //   border-radius: 8px;
//   //   width: 100%;
//   //   height: 287px;
// }

.leaflet-container {
  margin-top: 20px;
  border-radius: 8px;
  width: 100%;
  height: 287px;
}
.footer-address {
  font-size: 16px;
  font-weight: 400;
}
.footer__location {
  margin-bottom: 40px;
}
.footer__contacts-wrapper {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.footer-contacts-phone {
  color: var(--gold);
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
}
.footer-work-schedule {
  color: var(--gold);
  font-size: 16px;
  font-weight: 400;
}
.footer-menu-list {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
}
.footer-menu-list-item {
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  color: var(--white);
  transition: color var(--transition-dur-and-func);
  &:hover,
  &:focus {
    color: var(--gold);
    text-shadow: 0px 0px 4px 0px var(--gold);
  }
}
.footer__google-maps {
  color: var(--gold);
  font-size: 16px;
  font-weight: 400;
  border-bottom: none;
  transition: border-bottom var(--transition-dur-and-func);
  &:hover,
  &:focus {
    border-bottom: 1px solid var(--gold);
  }
}

.footer__location-links {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
}
@media screen and (min-width: 768px) {
  .footer__location-links {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .footer__contacts {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .footer__container {
    display: flex;
    flex-direction: column-reverse;
    gap: 40px;
  }
  .footer-menu-list {
    margin-top: 0px;
  }
  .footer__location {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 1200px) {
  .footer__container {
    flex-direction: row-reverse;
  }
  .footer__contacts {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 90px;
  }
  .leaflet-container {
    width: 856px;
    height: 354px;
  }
}
