.consultation {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: var(--gold);
}
.consultation__desc {
  color: var(--black-100);
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 8px;
}
.consultation__btn {
  color: var(--black-100);
  margin-left: auto;
  margin-right: auto;
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 16px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  // border-bottom: 1px solid var(--black-100);
  overflow: hidden;
  position: relative;
}

@media (max-width: 768px) {
  .consultation__btn::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: var(--black-100);
    bottom: 0;
    left: -100%;
    animation: run-animation 2s linear infinite;
  }

  @keyframes run-animation {
    0% {
      left: -100%;
    }
    100% {
      left: 100%;
    }
  }
}

/* Анимация при ховере на компьютере */
@media (min-width: 769px) {
  .consultation__btn {
    border-bottom: 2px solid var(--black-100);
    &:hover {
      border-bottom: none;
    }
  }

  .consultation__btn:hover::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: var(--black-100);
    bottom: 0;
    left: -100%;
    animation: run-animation-hover 2s linear infinite;
  }

  @keyframes run-animation-hover {
    0% {
      left: -100%;
    }
    100% {
      left: 100%;
    }
  }
}
