.swal-title {
  color: var(--gold);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 0;
}
.swal-title-ok {
  color: var(--gold);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 0;
  margin-bottom: 30px;
}
.swal2-backdrop-show {
  background: rgba(217, 217, 217, 0.1);
  box-shadow: 4px -8px 40px 2px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(100px);
}
.swal-html-container {
  text-align: center;
}

.swal2-image {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  padding: 0;
  margin-bottom: 40px;
}

.swal2-close {
  color: var(--white);
  width: 32px;
  height: 32px;
  margin: 0;
  position: absolute;
  top: 20px;
  right: 20px;
}
.swal-popup-custom {
  height: 443px;
  width: 370px;
  display: flex;
  padding: 40px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #3c3c3b;
  border-radius: 8px;
  background: linear-gradient(
    172deg,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0.8) 100%
  );
  box-shadow: 4px -8px 20px 2px rgba(0, 0, 0, 0.2),
    0px 0px 4px 2px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(100px);

  &--with-call-form {
    height: initial;
    width: min(500px, calc(100vw - 32px));
  }
}

.swal-text {
  color: var(--white);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0;
}
.swal-text-secondary {
  font-size: 24px;
  font-weight: 500;
}
.swal-text-link {
  color: var(--gold);
  text-decoration: underline;
}
.swal2-actions .swal2-confirm.swal2-styled {
  margin-top: 30px;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}
.swal2-confirm.swal2-styled {
  width: 247px;
  display: flex;
  padding: 20px 24px;
  justify-content: center;
  background-color: transparent;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 40px;
  border: 2px solid var(--gold);
  color: var(--gold);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.call-form__section {
  position: relative;
  padding-top: 64px;
  padding-bottom: 64px;
  // height: 589px;
  overflow: hidden;
}

.parallax-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.7) 0%,
      rgba(0, 0, 0, 0.7) 100%
    ),
    url('../images/call-form/bg-mobile.webp');
  background-size: cover;
  background-repeat: no-repeat;
}

.call-form__desc {
  font-family: Dela Gothic One;
  font-size: 24px;
  font-weight: 400;
  line-height: 127.3%;
  margin-bottom: 40px;
}
.call-form__desc-gold {
  color: var(--gold);
}
.call-form__form {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;

  border-radius: 8px;
  border: 1px solid #5f5f5f;
  background: linear-gradient(
    172deg,
    rgba(0, 0, 0, 0.25) 0%,
    rgba(0, 0, 0, 0.25) 100%
  );
  box-shadow: 4px -8px 40px 2px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(100px);

  &--in-modal[class] {
    width: initial;
    border: none;
    padding: 0;
  }
}

.call-form__form-input {
  display: flex;
  padding: 20px 24px;
  align-items: center;
  gap: 12px;
  height: 52px;
  align-self: stretch;
  border-radius: 8px;
  border: 2px solid #5f5f5f;
  background: rgba(0, 0, 0, 0.5);
  margin-bottom: 16px;

  color: var(--white);
  font-size: 16px;
  font-weight: 700;
  transition: border var(--transition-dur-and-func);

  &:focus {
    outline: none;
    border: 2px solid var(--gold);
  }
}
.call-form__form-textarea {
  display: flex;
  height: 92px;
  padding: 20px 24px;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #5f5f5f;
  resize: none;
  background: rgba(0, 0, 0, 0.5);
  margin-bottom: 16px;
  color: var(--white);
  font-size: 16px;
  font-weight: 700;

  transition: border var(--transition-dur-and-func);

  &:focus {
    outline: none;
    border: 1px solid var(--gold);
  }
}
.call-form__form-btn {
  height: 52px;
  display: flex;
  padding: 20px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;

  border-radius: 40px;
  border: 2px solid var(--white);

  color: var(--gold);
  font-size: 16px;
  font-weight: 700;
  transition: border var(--transition-dur-and-func),
    color var(--transition-dur-and-func);

  &:hover,
  &:focus {
    color: var(--gold);
    border: 2px solid var(--gold);
  }
}
.input-hint {
  margin-bottom: 10px;
  font-size: 14px;
  color: red;
}
@media screen and (min-width: 768px) and (max-width: 1199px) {
  .call-form__section {
    padding-top: 110px;
    padding-bottom: 100px;
  }
  .parallax-bg {
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.7) 0%,
        rgba(0, 0, 0, 0.7) 100%
      ),
      url('../images/call-form/bg-tablet.webp');
    background-size: cover;
    background-repeat: no-repeat;
  }
  .call-form__desc {
    font-size: 40px;
    text-align: center;
    margin-bottom: 40px;
  }
  .call-form__form {
    margin-left: auto;
    margin-right: auto;
    width: 528px;
  }
}
@media screen and (min-width: 1200px) {
  .call-form__section {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .parallax-bg {
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.7) 0%,
        rgba(0, 0, 0, 0.7) 100%
      ),
      url('../images/call-form/bg-desktop.webp');
    background-size: cover;
    background-repeat: no-repeat;
  }
  .call-form__desc {
    margin-bottom: 0;
    width: 624px;
  }
  .call-form__form {
    margin-left: 0;
    margin-right: 0;
    width: 440px;
  }
  .call-form__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  }
  .call-form__desc {
    font-size: 40px;
  }
}

.btn-to-order-service {
  border: 2px solid var(--white);
  color: var(--white);
  transition: color var(--transition-dur-and-func),
    border var(--transition-dur-and-func);
  border-radius: 40px;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 12px;
  padding: 20px 24px;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  max-width: 400px;
  width: 100%;
  margin-inline: auto;
  margin-block-start: 30px;

  &:hover,
  &:focus {
    border: 2px solid var(--gold-80);
    color: var(--gold);
  }
}


