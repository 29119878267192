.services {
  padding-top: 64px;
  padding-bottom: 64px;
  background-color: var(--black-90);
}
.services__title-wrapper {
  display: flex;
  padding: 20px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}
.services__title {
  font-family: Dela Gothic One;
  font-size: 24px;
  font-weight: 400;
}
.services__title-gold {
  color: var(--gold);
}

.accordion__title {
  width: 100%;
  display: flex;
  padding: 8px 0px;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  align-self: stretch;

  color: var(--gold-80);
  font-size: 24px;
  font-weight: 500;

  border-bottom: 1px solid var(--gold-80);
  margin-top: 40px;
  margin-bottom: 40px;
}
.services__desc-wrapper {
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.services__hot-icon {
  fill: var(--red);
}
.services__desc {
  font-size: 18px;
  font-weight: 700;
}

.accordion {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  margin-bottom: 20px;
}
.accordion-header {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px 8px;
  border-radius: 8px;
  border: 1px solid #5f5f5f;
  cursor: pointer;
}
.accordion-item {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  align-self: stretch;
}
.accordion-content {
  display: none;
  height: 0;
  overflow: hidden;
  transition: height 0.3 ease;
}

.accordion-content-desc {
  font-size: 14px;
  font-weight: 400;
}
.accordion-header__title {
  font-size: 14px;
  font-weight: 700;
}
.accordion-header__price {
  width: 125px;
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  font-size: 14px;
  font-weight: 700;
  color: var(--white);
}
.accordion-header__icon {
  margin-right: 8px;
  fill: var(--white);
}
.services__btn {
  width: 100%;
  display: flex;
  padding: 20px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 40px;
  border: 2px solid var(--white);

  color: var(--white);
  font-size: 16px;
  font-weight: 700;
  transition: color var(--transition-dur-and-func),
    border var(--transition-dur-and-func);

  &:hover,
  &:focus {
    border: 2px solid var(--gold-80);
    color: var(--gold);
  }
}
.services__btn-icon {
  fill: var(--white);
  transition: fill var(--transition-dur-and-func);

  .services__btn:hover &,
  .services__btn:hover & {
    fill: var(--gold);
  }
}
.accordion__category-list {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  align-content: center;
  align-self: stretch;
  flex-wrap: wrap;
}
.accordion__category-item {
  width: 141px;
  display: flex;
  padding: 10px;
  gap: 20px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 8px;
  transition: background-color var(--transition-dur-and-func);
  &:hover,
  &:focus {
    background-color: var(--black-gold);
  }
}
.accordion__category-category {
  font-size: 14px;
  font-weight: 700;
}
.accordion__category-price {
  font-size: 14px;
  font-weight: 500;
}
.accordion__category-icon {
  fill: var(--gold-80);
}
.accordion-item .accordion-header__icon {
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}
.accordion-item.active .accordion-header__icon {
  fill: var(--black-100);
  transform: rotate(45deg);
}
.accordion-item.active .accordion-header {
  background-color: var(--white);
}
.accordion-item.active .accordion-header__title {
  color: var(--black-100);
}

.accordion-item.active .accordion-content {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #5f5f5f;
  max-height: 1000px;
}

@media screen and (min-width: 768px) {
  .services {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .services__title-wrapper {
    padding: 20px 0px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 0;
    margin-bottom: 40px;
  }
  .accordion__category-item {
    width: 332px;
    padding: 20px;
  }
  .services__title {
    font-size: 40px;
  }
  .services__desc {
    font-size: 16px;
  }
  .accordion-content-desc {
    font-size: 16px;
  }
  .accordion-header__title {
    font-size: 24px;
  }
  .accordion-header__price {
    width: 180px;
    font-size: 24px;
  }
  .accordion-header__icon {
    margin-right: 20px;
  }
  .accordion__category-category {
    font-size: 24px;
  }
  .accordion__category-price {
    font-size: 24px;
  }
  .accordion__category-icon {
    width: 80px;
    height: 80px;
  }
}

@media screen and (min-width: 1200px) {
  .services {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .accordion__category-category {
    font-size: 16px;
  }
  .accordion__category-price {
    font-size: 16px;
  }
  .accordion-header__icon {
    transition: fill 0.3s ease;
    .accordion-header:hover &,
    .accordion-header:focus & {
      fill: var(--black-100);
    }
  }
  .accordion-header__title {
    color: var(--white);
    transition: color 0.3s ease;
    .accordion-header:hover &,
    .accordion-header:focus & {
      color: var(--black-100);
    }
  }
  .accordion-header__price {
    color: var(--white);
    transition: color 0.3s ease;
    .accordion-header:hover &,
    .accordion-header:focus & {
      color: var(--black-100);
    }
  }
  .accordion-header {
    background-color: transparent;
    transition: background-color 0.3s ease;
    &:hover,
    &:focus {
      background-color: var(--white);
    }
  }
  .accordion__category-item {
    width: 226px;
    align-items: baseline;
  }
}
