.header-container {
  display: flex;
  align-items: center;
  height: 64px;
  padding-top: 16px;
  padding-bottom: 16px;
}

.logo {
  display: flex;
  cursor: pointer;
  max-width: 230px;
}

.btn-burger {
  display: flex;
  margin-left: auto;
}

.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--black-100);
  z-index: 9999;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 16px 40px 16px;
}

.mobile-menu-list {
  margin-top: 56px;
  font-size: 16px;
  font-family: Inter;
}

.mobile-menu-list-item {
  margin-bottom: 20px;
  color: var(--white);
  transition: color var(--transition-dur-and-func);
  &:hover,
  &:focus {
    color: var(--gold);
    text-shadow: 0px 0px 4px 0px var(--gold);
  }
}

.btn-close {
  position: absolute;
  top: 16px;
  right: 16px;
}
.phone-icon-link {
  display: none;
}

.mobile-contacts-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.mobile-contacts-title {
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 400;
}
.mobile-social-list {
  margin-bottom: 20px;
  display: flex;
  gap: 24px;
}
.mobile-social-icon {
  fill: var(--white);
  transition: fill var(--transition-dur-and-func);
}

.mobile-social-item:hover .mobile-social-icon,
.mobile-social-item:focus .mobile-social-icon {
  fill: var(--gold-80);
}
.mobile-contacts-phone {
  margin-bottom: 8px;
  color: var(--gold);
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
}
.header-contacts-wrapper {
  display: none;
}
.mobile-work-schedule {
  color: var(--gold);
  font-size: 16px;
  font-weight: 400;
}
.header-address {
  display: none;
}
.nav-menu-list {
  display: none;
}
@media screen and (min-width: 768px) {
  .mobile-menu-list {
    margin-top: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid var(--white);
    display: flex;
    justify-content: space-between;
  }
  .menu-overlay {
    padding: 16px 22px 20px 22px;
  }
  .mobile-social-icon {
    fill: var(--white);
    transition: fill var(--transition-dur-and-func);
  }

  .mobile-social-item:hover .mobile-social-icon,
  .mobile-social-item:focus .mobile-social-icon {
    fill: var(--gold-80);
  }

  .header-address {
    font-size: 16px;
    font-weight: 400;
    margin-top: 8px;
    display: flex;
    cursor: pointer;
    color: var(--white);
    border-bottom: none;
    transition: color var(--transition-dur-and-func);
    &:hover,
    &:focus {
      border-bottom: 1px solid var(--white);
    }
  }
  .phone-icon-link {
    display: flex;
    position: absolute;
    top: 38px;
    right: 76px;
  }
  .btn-close {
    top: 36px;
  }
  .header-container {
    height: 105px;
  }
  .nav-menu-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid var(--white);
  }
  .nav-menu-list-item {
    padding: 4px 8px;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    color: var(--white);
    transition: color var(--transition-dur-and-func);
    &:hover,
    &:focus {
      color: var(--gold);
      text-shadow: 0px 0px 4px 0px var(--gold);
    }
  }
}

@media screen and (min-width: 1200px) {
  .btn-burger {
    display: none;
  }
  .nav-menu-list {
    justify-content: center;
    gap: 20px;
  }
  .header-container {
    height: 94px;
  }
  // header .header-container,
  // header .nav__container {
  //   max-width: 1600px;
  // }
  .header-wrapper-adrress {
    display: flex;
    align-items: center;
    gap: 40px;
  }
  .header-address {
    margin-top: 0;
  }
  .mobile-contacts-title {
    text-align: center;
  }
  .header-contacts-wrapper {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    align-items: center;
    gap: 40px;
  }
  .mobile-social-list {
    margin-bottom: 0;
  }
  .mobile-work-schedule {
    margin-top: 4px;
  }
  .mobile-social-icon {
    fill: var(--white);
    transition: fill var(--transition-dur-and-func);
  }
  .mobile-social-item:hover .mobile-social-icon,
  .mobile-social-item:focus .mobile-social-icon {
    fill: var(--gold-80);
  }
}
@media screen and (min-width: 1601px) {
  header .header-container,
  header .nav__container {
    padding-left: 0;
    padding-right: 0;
  }
}
