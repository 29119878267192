#overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(217, 217, 217, 0.1);
  box-shadow: 4px -8px 40px 2px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(40px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  visibility: hidden;
}

.loader {
  border: 18px solid var(--black-100);
  border-top: 18px solid var(--gold);
  border-left: 18px solid var(--gold);
  border-bottom: 18px solid var(--gold);
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
