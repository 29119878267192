@import './common';

@import './layout/header';
@import './layout/advantages';
@import './layout/about';
@import './layout/services';
@import './layout/hero';
@import './layout/line';
@import './layout/partners';
@import './layout/slider';
@import './layout/gallery';
@import './layout/STO-services';
@import './layout/call-form';
@import './layout/reviews';
@import './layout/consultation';
@import './layout/lounge-zone';
@import './layout/footer';
@import './layout/social-media';

@import './layout/hero.scss';
@import './layout/services-detailing';

@import './base/base';
@import './base/container';

@import './utils/variables';
@import './utils/visually-hidden';

@import './components/button-up';
@import './components/loader';
